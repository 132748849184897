<template>
  <HeadlineBlock
    :content="item.fields.headlineText"
    :badge-text="item.fields.badge?.fields?.title"
    :content-centered="item.fields.headingPosition === 'centered'"
    :level="level"
    :cols="mappedCols"
    :tag="item.fields.headingMarkup?.toLowerCase()"
    :cols-centered="item.fields.headingColumnPosition === 'centered'"
  />
</template>

<script setup lang="ts">
import { HeadlineBlock } from '@hypercodestudio/basler-components';
import { mapColsWidth } from '~/utils/mapColsWidth';
import { mapHeadlineLevel } from '~/utils/mapHeadlineLevel';
import type { IHeadline } from '~/lib/ContentfulService';

interface Props {
  item: IHeadline;
}

const props = defineProps<Props>();

const level = computed(() =>
  mapHeadlineLevel(props.item.fields.headingDisplaySize ?? 'H2')
);

const mappedCols = computed(() => mapColsWidth(props.item.fields.headingWidth));
</script>
